import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card ab-card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap card-title" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.organizationId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              _createElementVNode("li", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-active-primary me-6",
                  to: "PatientTransactionsList",
                  "active-class": "active",
                  style: {"color":"#494B74"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Patient Transactions ")
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_6, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-active-primary me-6",
                  to: "InsuranceTransactionsList",
                  "active-class": "active",
                  style: {"color":"#494B74"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Insurance Transactions ")
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "nav-link text-active-primary me-6",
                  to: "PaymentPlanList",
                  "active-class": "active",
                  style: {"color":"#494B74"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Payment Plans ")
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_view)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}