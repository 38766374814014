
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";

export default defineComponent({
  name: "Payment Page",
  components: {},
  props: [],
  setup(props) {
    let organizationId = ref<string | null>("");

    onMounted(async () => {
      organizationId.value = getOrganization();
    });

    return {
      organizationId,
    };
  },
});
